<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Cafe24 주문 현황(eSim)</h5>
                <p class="mb-0 text-sm">
                  
                </p>
              </div>
            </div>
            <div class="">
              
              <form class="row row-cols-lg-auto g-3 align-items-end">
                <div v-if="false" class="col-12">
                  <div class="form-group mb-0">
                    <label>상품유형</label>
                    <select v-model="searchProdType"  class="form-control">
                      <option value="">전체</option>
                      <option value="ESIM">ESIM</option>
                      <option value="USIM">USIM</option>
                      <option value="WIFI">WIFI</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>주문번호</label>
                    <div class="input-group mb-0">
                      <input v-model="searchOrderId" type="text" class="form-control" placeholder="주문번호" aria-label="주문번호">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>주문자 성명</label>
                    <div class="input-group mb-0">
                      <input v-model="searchBuyerName" type="text" class="form-control" placeholder="주문자 성명" aria-label="주문자 성명">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>이메일</label>
                    <div class="input-group mb-0">
                      <input v-model="searchBuyerEmail" type="text" class="form-control" placeholder="이메일" aria-label="이메일">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>전화번호</label>
                    <div class="input-group mb-0">
                      <input v-model="searchBuyerCellphone" type="text" class="form-control" placeholder="전화번호" aria-label="전화번호">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>Cafe24 상품명</label>
                    <div class="input-group mb-0">
                      <input v-model="searchProductName" type="text" class="form-control" placeholder="Cafe24 상품명" aria-label="Cafe24 상품명">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>주문일</label>
                    <div class="input-group mb-0">
                      <flat-picker
                        v-model="searchOrderDateRange"
                        :config="{ allowInput: true, mode: 'range', parseDate: true }"
                        class="form-control datepicker"
                        @on-open="focus"
                        @on-close="blur"
                        @on-change="onDateChange"
                      >
                      </flat-picker>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label>처리상태</label>
                    <div class="input-group mb-0">
                      <select v-model="searchStatus"  class="form-control">
                        <option value="">전체</option>
                        <option value="R">배송대기</option>
                        <option value="S">배송성공</option>
                        <option value="F">배송실패</option>
                        <option value="C">재고부족</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="button" class="btn btn-primary mb-0" @click="getCafe24OrderList()">조회</button>
                  <button type="button" class="btn btn-secondary mb-0 ms-1" @click="initSearchData()">
                    <span class="btn-inner--icon"><i class="fas fa-sync-alt"></i></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div ref="table"></div>
            <!-- <div class="table-responsive">
              <table id="esim-list" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      주문번호
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      주문자
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div>이메일</div>
                      <div>전화번호</div>
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div>Cafe24상품명 / 옵션명</div>
                      <div>[자사 상품코드] 상품명</div>
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      <div>주문일시</div>
                      <div>배송일시</div>
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      처리상태
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      재배송
                    </th>
                    <th
                      class="text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      메모
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr  v-for="(item, idx) in cafe24OrderList" :key="item">
                    <td class="text-center">
                      {{item.cafe24_order_id}}
                    </td>
                    <td class="text-left">
                      {{item.cafe24_buyer_name}}
                    </td>
                    <td class="text-left">
                      <div>{{item.email_address}}</div>
                      <div>{{item.cafe24_buyer_cellphone}}</div>
                    </td>
                    <td class="text-left">
                      <div>{{item.cafe24_product_name}} / </div>
                      <div>[{{item.prod_code}}-{{item.opt_code}}] {{item.prod_name}}</div>
                    </td>
                    <td class="align-middle text-left text-sm">
                      <div>&nbsp;{{getFullDateString(item.cafe24_order_date)}}</div>
                      <div>&nbsp;{{getFullDateString(item.recent_send_email_at)}}</div>
                    </td>
                    <td class="align-middle text-center">
                      <div v-if="item.status=='F'" class="cursor-hand" :class="getCafe24OrderStatusClass(item.status)" data-bs-toggle="tooltip" :data-bs-title="item.mail_send_message">{{getCafe24OrderStatusName(item.status)}}</div>
                      <div v-else-if="item.status=='A'" class="cursor-hand" :class="getCafe24OrderStatusClass(item.status)" data-bs-toggle="tooltip" :data-bs-title="item.json_desc">
                        {{getCafe24OrderStatusName(item.status)}}<br>
                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="reOrderAPI(idx)"><i class="fas fa-redo cursor-hand"></i></button>
                      </div>
                      <div v-else :class="getCafe24OrderStatusClass(item.status)" >{{getCafe24OrderStatusName(item.status)}}</div>
                    </td>
                    <td class="align-middle text-center">
                      <button type="button" class="btn btn-outline-secondary btn-sm mb-0" :disabled="item.status=='N' || item.status=='C' || item.status=='A'" @click="showEsimOrderInfodModal(item)"><i class="fas fa-envelope cursor-hand" ></i></button>
                    </td>
                    <td class="align-middle text-center">
                      <i class="far fa-sticky-note cursor-hand" @click="showEsimOrderCommentModal(item)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div id="modal-comment" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-info" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">메모 ({{orderId}})</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <textarea v-model="memo" type="text" class="form-control" placeholder="" aria-label="" style="resize:none;height:100px;"></textarea>
                      <soft-button color="info" @click="saveMemo()">기록</soft-button>
                    </div>
                  </div>
                </form>
                <div class="memo-wrap">
                  <ul class="list-group">
                    <li v-if="commentList.length == 0" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg text-center">
                      등록된 메모가 없습니다.
                    </li>
                    <li
                      v-for="(item, index) of commentList"
                      :key="index"
                      class="list-group-item border-0 d-flex p-2 mb-2 bg-gray-100 border-radius-lg"
                    >
                      <div class="d-flex flex-column">
                        <h6 class="mb-3 text-sm">{{ item.memo }}</h6>
                      </div>
                      <div class="ms-auto text-end">
                        <span class="text-secondary h6" style="font-size:0.7rem;">{{ getFullDateString(item.create_at) }}</span>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="card-footer">
                <soft-button color="secondary" full-width="true" @click="modalComment.hide()">닫기</soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="modal-esim-order-info" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-esim-info" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-primary text-gradient">재배송</h3>
              </div>
              <div class="card-body">
                <form role="form text-left" @submit.prevent>
                  <div class="form-group">
                    <label>주문번호</label>
                    <div class="input-group mb-3">
                      <input v-model="orderId" type="text" class="form-control" placeholder="주문번호" aria-label="주문번호" disabled>
                    </div>
                  </div>
                  <label>주문자</label>
                  <div class="input-group mb-3">
                    <input v-model="buyerName" type="text" class="form-control" placeholder="주문자" aria-label="주문자" disabled>
                  </div>
                  <label>이메일</label>
                  <div class="input-group mb-3">
                    <input v-model="emailAddress" type="text" class="form-control" placeholder="이메일" aria-label="이메일">
                  </div>
                  <label>자사상품코드</label>
                  <div class="input-group mb-3">
                    <input v-model="prodCode" type="text" class="form-control" placeholder="자사상품코드" aria-label="자사상품코드" disabled>
                  </div>
                  <label v-show="optCode!=''">자사옵션코드</label>
                  <div v-show="optCode!=''" class="input-group mb-3">
                    <input v-model="optCode" type="text" class="form-control" placeholder="자사옵션코드" aria-label="자사옵션코드" disabled>
                  </div>
                  <label v-show="prodType=='ESIM'">SIM 일련번호</label>
                  <div v-show="prodType=='ESIM'" class="input-group mb-3">
                    <input v-model="iccid" type="text" class="form-control" placeholder="SIM 일련번호" aria-label="SIM 일련번호" disabled>
                    <soft-button color="secondary"  :disabled="disabledEsimChange" @click="changeEsim()"><i class="fas fa-sync-alt"></i></soft-button>
                  </div>
                  <label  v-show="prodType=='ESIM'">SIM 변경여부</label>
                  <div  v-show="prodType=='ESIM'" class="form-check form-switch">
                    <input id="flexSwitchCheckDefault" v-model="esimChange" class="form-check-input" type="checkbox" checked="">
                    <label class="form-check-label" for="flexSwitchCheckDefault">{{esimChange?"변경":"변경안함"}}</label>
                  </div>
                  <div class="text-center">
                    <div class="row">
                      <div class="col-sm">
                        <soft-button color="primary" full-width="true" @click="resendEmail()">재배송</soft-button>
                      </div>
                      <div class="col-sm">
                        <soft-button color="secondary"  full-width="true" @click="modalEsimOrderInfo.hide()">취소</soft-button>
                      </div>
                    </div>  
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import { Modal } from "bootstrap";
import SoftButton from "@/components/SoftButton.vue";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {TabulatorFull as Tabulator} from 'tabulator-tables'; 

export default {
  name: "Cafe24OrderList",
  components: {
    SoftButton,
    flatPicker,
  },
  data() {
    return {
      searchProdType: "ESIM",
      searchOrderId: "",
      searchBuyerName: "",
      searchBuyerEmail: "",
      searchBuyerCellphone: "",
      searchProductName: "",
      searchOrderDateRange: null,
      searchOrderDateStart: "",
      searchOrderDateEnd: "",
      searchStatus: "",

      cafe24OrderList: [],

      commentList: [],
      orderId: "0",
      memo: "",
      editEsimOrderItemNo: 0,
      editEsimOrderMappingNo: 0,

      // no: 0,
      // prodNo: 0,
      // prodCode: "",
      // prodName: "",
      // simType: "",
      // simNum: "",
      // expireDate: "",
      // actCode: "",
      // status: "",
      // statusName: "",
      // changeStatus: "",

      prodType: "",
      esimNo: 0,
      buyerName: "",
      orgEmailAddress: "",
      emailAddress: "",
      prodCode: "",
      optCode: "",
      iccid: "",

      disabledEsimChange: false,

      esimChange: true,

      tabulator: null, //variable to hold your table
      
    }
  },
  computed: {
    getCafe24OrderStatusName() {
      return (status) => {
        if(status === "R") {
          return "배송대기"
        }
        else if(status === "S") {
          return "배송성공"
        }
        else if(status === "F") {
          return "메일전송실패"
        }
        else if(status === "A") {
          return "CMI연동실패"
        }
        else if(status === "C") {
          return "재고부족"
        }
        else if(status === "N") {
          return "매창상품없음"
        }
      }
    },
    getFullDateString() {
      return (date) =>{
        return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : " "
      }
    }, 
    getCafe24OrderEmail() {
      return (item) =>{
        if(item.cafe24_order_place_id == "shopn") 
          return item.cafe24_shipping_message
        else
          return item.cafe24_buyer_email
      }
    }, 
    getCafe24OrderStatusClass() {
      return (status) => {
        if(status=="F" || status=="A" || status=="N" || status=="C") {
          return 'text-danger';
        }
        else if(status=="S") {
          return 'text-success';
        }
        else {
          return '';
        }
      }
    },
  },
  mounted() {
    dayjs.extend(customParseFormat)
    dayjs.extend(utc);
    dayjs.extend(timezone);

    this.tabulator = new Tabulator(this.$refs.table, {
      data: this.productList, //link data to table
      reactiveData:true, //enable data reactivity
      renderHorizontal:"virtual",
      pagination:"local",
      paginationSize:50,
      paginationSizeSelector:[25, 50, 100],
      paginationCounter:"rows",
      columns: [
        { title: "주문번호", field: "cafe24_order_id", vertAlign:"middle"},
        { title: "주문자", field: "cafe24_buyer_name", vertAlign:"middle"},
        { title: "이메일<br>전화번호", field: "email_address",formatter:this.columnFormatterHandeler, formatterParams: "email"},
        { title: "Cafe24상품명 / 옵션명<br>[자사 상품코드] 상품명", field: "cafe24_product_name", formatter:this.columnFormatterHandeler, formatterParams: "product"},
        { title: "주문일시<br>배송일시", field: "cafe24_order_date", formatter:this.columnFormatterHandeler, formatterParams: "date"},
        { title: "처리상태", formatter:this.columnFormatterHandeler, formatterParams: "status", cellClick: this.reOrderClickHandeler, vertAlign:"middle", headerSort:false },
        { title: "재배송", formatter:this.columnFormatterHandeler, formatterParams: "resend", cellClick: this.resendClickHandeler, vertAlign:"middle", headerSort:false},
        { title: "메모", formatter:this.columnFormatterHandeler, formatterParams: "memo", cellClick: this.memoClickHandeler, vertAlign:"middle", headerSort:false},
      ], //define table columns
    });

    if (document.getElementById("cafe24-order-list")) {
      // const adminDataTable = new DataTable("#admin-list", {
      //   searchable: false,
      //   fixedHeight: false,
      //   perPage: 10
      // });

      // adminDataTable.body.addEventListener("click", e => {
      //   console.log("DataTable CLick Event ");
      //   console.log(e.target.getAttribute("data-id"));
      //   console.log(e.target.getAttribute("data-type"));
      //   if (e.target.getAttribute("data-type") === "EDIT") {

      //     const index = parseInt(e.target.getAttribute("data-index"), 10)
      //     // const row = adminDataTable.activeRows[index]
      //     // let message = [
      //     //   "This is row ",
      //     //   (row.rowIndex),
      //     //   " of ",
      //     //   adminDataTable.options.perPage,
      //     //   " rendered rows and row ",
      //     //   (index + 1),
      //     //   " of ",
      //     //   adminDataTable.data.length,
      //     //   " total rows."
      //     // ]

      //     // console.log(adminDataTable.data);

      //     // const data = [].slice.call(row.cells).map(cell => cell.data)

      //     // message = message.join("");

      //     // message = message + "\n\nThe row data is:\n" + JSON.stringify(data)

      //     // alert(message)

      //     this.showAdminModal(true, index)
      //   }
      // })

    }
    this.getCafe24OrderList()

    const mEsimOrderInfo = document.getElementById('modal-esim-order-info')
      mEsimOrderInfo.addEventListener('hidden.bs.modal', event => {
        console.log(event)
      })
  },
  methods: {
    initSearchData() {
      this.searchOrderId = ""
      this.searchBuyerName = ""
      this.searchBuyerEmail = ""
      this.searchBuyerCellphone = ""
      this.searchProductName = ""
      this.searchOrderDateRange = null
      this.searchStatus = ""
      this.searchProdType = "ESIM"
    }, 
    initEsimOrderInfoData() {
      this.editEsimOrderItemNo = 0
      this.editEsimOrderMappingNo = 0
      this.prodType = ""
      this.esimNo = 0
      this.orderId = ""
      this.buyerName = ""
      this.orgEmailAddress = ""
      this.emailAddress = ""
      this.prodCode = ""
      this.optCode = ""
      this.iccid = ""

      this.disabledEsimChange = false
    },
    getCafe24OrderList() {

      const aryRangeDate = this.searchOrderDateRange ? this.searchOrderDateRange.split(" to ") : []

      // var vm = this;
      const param = {
        "searchProdType" : this.searchProdType,
        "searchOrderId" : this.searchOrderId,
        "searchBuyerName" : this.searchBuyerName,
        "searchBuyerEmail" : this.searchBuyerEmail,
        "searchBuyerCellphone" : this.searchBuyerCellphone,
        "searchProductName" : this.searchProductName,
        "searchStatus" : this.searchStatus,
      }

      if(aryRangeDate.length == 1) {
        param.searchOrderDateStart = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        param.searchOrderDateEnd = dayjs(this.searchOrderDateRange,"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }
      else if(aryRangeDate.length == 2) {
        param.searchOrderDateStart = dayjs(aryRangeDate[0],"YYYY-MM-DD").startOf("date").format("YYYY-MM-DD HH:mm:ss")
        param.searchOrderDateEnd = dayjs(aryRangeDate[1],"YYYY-MM-DD").endOf("date").format("YYYY-MM-DD HH:mm:ss")
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/list", param, { timeout: 20000 }).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.cafe24OrderList = response.data.result

        this.tabulator.setData(this.cafe24OrderList);

        // this.esimList.forEach((item) => {
        //   item.sim_num = ""

        //   if(item.iccid !== "") {
        //     item.sim_num = item.iccid
        //   }
        //   else if(item.imsi !== "") {
        //     item.sim_num = item.imsi
        //   }
        //   else if(item.msisdn !== "") {
        //     item.sim_num = item.msisdn
        //   }
        // })
      })
    },
    reOrderAPI(idx) {

      if(!confirm("CMI 주문연동을 다시하시겠습니까?")) {
        return;
      }

      const reOrderItem = this.cafe24OrderList[idx]

      // var vm = this;
      const param = {
        "esimOrderMappingNo" : reOrderItem.esim_order_mapping_no,
        "prodCode" : reOrderItem.prod_code,
        "optCode" : reOrderItem.opt_code,
        "esimNo": reOrderItem.esim_no,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/reorder_cmi", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data);

        const resultOrderCmi = response.data;

        if(resultOrderCmi.statusCode == "0000"){
          alert("CMI 주문요청을 하였습니다.")

          this.getCafe24OrderList()
        }
        else {
          alert("CMI 주문요청 시 오류가 발생하였습니다.\n"+resultOrderCmi.message);

          this.getCafe24OrderList()
        }

      })
    },
    resendEmail() {

      if(this.emailAddress == "") {
        alert("이메일 주소를 입력해주세요.");
        return;
      }

      let confirmMessage = "";
      
      if(this.orgEmailAddress != this.emailAddress) {
        confirmMessage = "이메일 주소가 변경되었습니다.\n변경된 이메일로 전송을 다시하시겠습니까?";
      }
      else {
        confirmMessage = "이메일 전송을 다시하시겠습니까?";
      }

      if(!confirm(confirmMessage)) {
        return;
      }

      // var vm = this;
      const param = {
        "prodType": this.prodType,
        "esimOrderItemNo" : this.editEsimOrderItemNo,
        "esimOrderMappingNo" : this.editEsimOrderMappingNo,
        "orgEmailAddress": this.orgEmailAddress,
        "emailAddress": this.emailAddress,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/resend", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        const resultResend = response.data;
        
        if(resultResend.statusCode == "0000"){
          alert("이메일전송을 다시 하였습니다.")

          this.modalEsimOrderInfo.hide()

          this.getCafe24OrderList()
        }
        else {
          alert("이메일전송 시 오류가 발생하였습니다.\n"+resultResend.message);

          this.getCafe24OrderList()
        }

      })
    },

    changeEsim() {

      if(!confirm("eSIM 변경 작업을 진행하시겠습니까?")) {
        return;
      }

      // var vm = this;
      const param = {
        "esimOrderMappingNo" : this.editEsimOrderMappingNo,
        "esimNo": this.esimNo
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/changeEsim", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        
        if(response.data.statusCode == "0000"){
          const resultChangeEsim = response.data.result[0];

          if(resultChangeEsim.esimOrderMappingStatus == "N") {
            alert("매칭 상품이 없습니다.");
          }
          else if(resultChangeEsim.esimOrderMappingStatus == "C") {
            alert("재고가 부족합니다.");
          }
          else if(resultChangeEsim.esimOrderMappingStatus == "A") {
            alert("eSIM 변경은 완료되었으나 CMI API연동 시 오류가 발생하였습니다.");
          }
          else if(resultChangeEsim.esimOrderMappingStatus == "R") {
            alert("eSIM 변경 작업이 완료되었습니다.");
            this.iccid = resultChangeEsim.iccid;
            this.disabledEsimChange = true
          }


          this.getCafe24OrderList()
        }
        else {
          alert("eSIM 변경 시 오류가 발생하였습니다.\n"+response.data.message);

          this.getCafe24OrderList()
        }

      })
    },

    showEsimOrderInfodModal(item) {
      console.log("showEsimOrderInfodModal CALL");

      this.initEsimOrderInfoData();

      this.editEsimOrderItemNo = item.esim_order_item_no;
      this.editEsimOrderMappingNo = item.esim_order_mapping_no;

      this.prodType = item.prod_type;

      this.esimNo = item.esim_no;
      this.orderId = item.cafe24_order_id
      this.buyerName = item.cafe24_buyer_name;
      this.orgEmailAddress = item.email_address;
      this.emailAddress = item.email_address;
      this.prodCode = item.prod_code;
      this.optCode = item.opt_code;
      this.iccid = item.iccid;


      this.modalEsimOrderInfo = new Modal(document.getElementById('modal-esim-order-info'))
      
      this.modalEsimOrderInfo.show()

    },  

    showEsimOrderCommentModal(item) {

      this.commentList = [];
      this.orderId = item.cafe24_order_id;
      this.editEsimOrderMappingNo = item.esim_order_mapping_no;
      this.memo = "";

      this.getEsimOrderComment(item.esim_order_mapping_no);

      

      this.modalComment = new Modal(document.getElementById('modal-comment'))
      this.modalComment.show()


    },
    getEsimOrderComment(no) {

      const param = {
        "esimOrderMappingNo" : no,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/commentList", param).then((response) => { // 실제 API를 요청한다/
        console.log(response.data.result);

        this.commentList = response.data.result
      })
    },

    saveMemo() {
      const param = {
        esimOrderMappingNo : this.editEsimOrderMappingNo,
        memo: this.memo,
      }

      this.$axios.post(process.env.VUE_APP_BACKEND_URL+"api/cafe24_order/saveComment", param).then((response) => { // 실제 API를 요청한다/

        const resultMemo = response.data;
        
        if(resultMemo.statusCode == "0000"){
          alert("메모를 등록하였습니다.")

          this.getEsimOrderComment(this.editEsimOrderMappingNo)
        }
        else {
          alert("메모등록 시 오류가 발생하였습니다.\n"+resultMemo.message);

          this.getEsimOrderComment(this.editEsimOrderMappingNo)
        }

      })
    },

    columnFormatterHandeler(cell, formatterParams) {
      const content = document.createElement("span");
      const data = cell.getRow().getData();


      
      if(formatterParams == "email") {
        content.innerHTML =  `
          <div>${data.email_address}</div>
          <div>${data.cafe24_buyer_cellphone}</div>
        `;
      }
      else if(formatterParams == "product") {
        content.innerHTML =  `
          <div>${data.cafe24_product_name} / </div>
          <div>[${data.prod_code}-${data.opt_code}] ${data.prod_name}</div>
        `;
      }
      else if(formatterParams == "date") {
        content.innerHTML =  `
          <div>&nbsp;${this.getFullDateString(data.cafe24_order_date)}</div>
          <div>&nbsp;${this.getFullDateString(data.recent_send_email_at)}</div>
        `;
      }
      else if(formatterParams == "status") {
        const className = this.getCafe24OrderStatusClass(data.status);
        const statusName = this.getCafe24OrderStatusName(data.status);
        let bsTitle = "";
        if(data.status=="F") {
          bsTitle = data.mail_send_message;
          content.innerHTML = `<div class="cursor-hand ${className}" data-bs-toggle="tooltip" data-bs-title="${bsTitle}">${statusName}</div>`;
        }
        else if(data.status=="A") {
          bsTitle = data.json_desc;
          content.innerHTML = `<div class="cursor-hand ${className}" data-bs-toggle="tooltip" data-bs-title="${bsTitle}">${statusName}<br><button type="button" class="btn btn-outline-secondary btn-sm click-class" @click="reOrderAPI(idx)"><i class="fas fa-redo cursor-hand click-class"></i></button></div>`;
        }
        else {
          content.innerHTML = `<div class="cursor-hand ${className}">${statusName}</div>`;
        }
      }
      else if(formatterParams == "resend") {
        if(data.status == "N" || data.status == "C" || data.status == "A") {
          content.innerHTML =  `<button type="button" class="btn btn-outline-secondary btn-sm mb-0" disabled="true"><i class="fas fa-envelope cursor-hand" ></i></button>`;  
        }
        else {
          content.innerHTML =  `<button type="button" class="btn btn-outline-secondary btn-sm mb-0  click-class"><i class="fas fa-envelope cursor-hand  click-class" ></i></button>`;  
        }
      }
      else if(formatterParams == "memo") {
        content.innerHTML =  `
          <i class="far fa-sticky-note cursor-hand click-class"></i>
        `;
      }

      return content
    },

    reOrderClickHandeler(e, cell) {
      const target = e.target;

      if(target.classList.contains("click-class")){
        const data = cell.getRow().getData();

        const esim_order_mapping_no = data.esim_order_mapping_no

        const idx = this.cafe24OrderList.findIndex(x => x.esim_order_mapping_no == esim_order_mapping_no)

        this.reOrderAPI(idx);
      }
    },

    resendClickHandeler(e, cell) {
      const target = e.target;

      if(target.classList.contains("click-class")){
        const data = cell.getRow().getData();

        this.showEsimOrderInfodModal(data);
      }
    },

    memoClickHandeler(e, cell) {
      const target = e.target;

      if(target.classList.contains("click-class")){
        const data = cell.getRow().getData();

        this.showEsimOrderCommentModal(data)
      }
    }
    

  },
};
</script>

<style scoped >
  .memo-wrap {
    height: 300px;
    overflow-y:scroll;
  }
</style>
